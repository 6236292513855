// function openNav() {
//
//
//     document.getElementById("myNav").style.width = "100%";
//
//
// }
//
// function closeNav() {
//     document.getElementById("myNav").style.width = "0%";
// }

function openNav() {
    const nav = document.getElementById("myNav");

    if (nav.style.width == '100%') {
        nav.style.width = "0%";
        // nav.style.opacity = 0;
    } else {
        nav.style.width = "100%";
        // nav.style.opacity = 1;
    }
}


